// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import JQuery from "jquery";
window.$ = window.JQuery = JQuery;
import "bootstrap";
import "../src/application";
import "./jquery.highlight-5";
import toastr from "toastr";
toastr.options = {
  closeButton: true,
};
window.toastr = toastr;

Rails.start();
ActiveStorage.start();

//タッチデバイスでの:hoverの挙動を無効化
$(function () {
  var touch =
    "ontouchstart" in document.documentElement ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;

  if (touch) {
    try {
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si];
        if (!styleSheet.rules) continue;

        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue;

          if (styleSheet.rules[ri].selectorText.match(":hover")) {
            styleSheet.deleteRule(ri);
          }
        }
      }
    } catch (ex) {}
  }

  //ヘッダー関連の動作

  //（画面の幅が一定数以下の時に）ヘッダーのメニューのみを追従する
  $(window).scroll(function () {
    if ($(this).scrollTop() > 45) {
      $("#globalHeader .menu_box").addClass("fixed");
    } else {
      $("#globalHeader .menu_box").removeClass("fixed");
    }
  });

  //通知リスト・マイページメニューを閉じる
  var notification = $("#notificationList");
  var mymenu = $("#mypageMenu");

  function reset_notification() {
    notification.collapse("hide");
  }
  function reset_mymenu() {
    mymenu.collapse("hide");
  }

  //通知リストを開いた時
  notification.on("shown.bs.collapse", function () {
    //メニュー本体以外をクリックしたらメニューを閉じる
    $(document).on("click touchend", function (e) {
      if (!$(e.target).closest("#notificationList").length) {
        reset_notification();
      }
    });
  });
  //マイページメニューを開いた時
  mymenu.on("shown.bs.collapse", function () {
    //メニュー本体以外をクリックしたらメニューを閉じる
    $(document).on("click touchend", function (e) {
      if (!$(e.target).closest("#mypageMenu").length) {
        reset_mymenu();
      }
    });
  });
});

//テキストエリアの文字数カウント
$(function () {
  $("textarea").keyup(function () {
    //テキストエリアに入力できる残り文字数をカウント
    var targetArea = $(this).closest(".js_counting"); //影響範囲の指定
    var count = $(this).val().replace(/\n/g, "改行").length; //現在の入力文字数を取得
    var limit = targetArea.find(".limit").text(); //入力可能な最大文字数を取得
    var limitNum = Number(limit); //入力可能な最大文字数（文字列）を数値に変換
    var remaining = limitNum - count; //入力可能な残り文字数を算出
    targetArea.find(".count").text(remaining); //残り文字数を表示

    //文字数制限を超えたら警告用のセレクタを付与
    var countBox = targetArea.find(".count");
    if (limitNum < count) {
      countBox.addClass("is_over");
    } else {
      countBox.removeClass("is_over");
    }
  });
});

// 「DM利用時の注意事項」に同意済みかどうかの判定
$(function () {
  var ss = sessionStorage;
  // セッションストレージから訪問履歴を読み込み
  var dm = sessionStorage.getItem("dm_agreement");
  var btn_done = $(".js_dm_agreement_done");
  var btn_yet = $(".js_dm_agreement_yet");
  var btn_set = $(".js_dm_agreement_set");

  // このセッション中の同意履歴の有無によって表示するボタンを変える
  if (dm === "done") {
    $(btn_yet).hide();
  } else {
    $(btn_done).hide();
  }

  $(function () {
    $(btn_set).on("click", function () {
      // 同意済みであることをセッションストレージに書き込み
      ss.setItem("dm_agreement", "done");
    });
  });

  //モーダル内のボタンのリンクのURLを設定
  btn_yet.on("click", function () {
    var url = $(this)
      .closest(".js_dm_agreement_box")
      .find(btn_done)
      .attr("href");
    $(btn_set).attr("href", url);
  });
});

//評価選択：選択中の点数を表示
$(function () {
  $(".starRating_select input").on("change", function () {
    var rate = $(this).val();
    $(".starRating_select_state").text(rate);
  });
});

// 仕事概要カード用のjs
$(function () {
  var job = $(".jobBox");

  // 掲載日が3日以内の仕事に「新着」ラベルを付与
  $(job).each(function () {
    // 現在日時
    var current = new Date();
    // 3日前のミリ秒（new表示の適用範囲）
    var range_ms = current.getTime() - 3 * 24 * 60 * 60 * 1000;
    // 掲載日時
    var publication = new Date(
      $(this).find(".publicationDate time").attr("datetime")
    );
    // 掲載日時のミリ秒
    var publication_ms = publication.getTime();

    // 掲載期限が指定期限以内ならclassを付与
    if (range_ms < publication_ms) {
      $(this).addClass("is_newArrival");
    }
  });

  // 残りの応募期限によって表示を変更
  $(job).each(function () {
    // 残り募集期限
    var limit = $(this).find(".timelimit").text();

    // 掲載終了した仕事に「掲載終了」ラベルを付与（残り期限が「0」と完全一致したらclassを付与）
    if (limit === "0") {
      $(this).addClass("is_close");
    }

    // 残り期限が「1」と完全一致したらclassを付与
    if (limit === "1") {
      $(this).find(".timelimitBox").addClass("txt_attention");
    }
  });
});

// 検索結果のハイライト
$(function () {
  // 検索ワードの取得
  var keyword = $('#jobSearchForm input[type="text"]').val();
  // ハイライトの適用範囲の設定
  $("#content_searchResult").highlight(keyword);
});

// コメント
$(function () {
  // コメント欄に入力されたら、送信ボタンを活性化して押せるようにする
  $(".commentInput_box .commentInput").on("keyup keydown", function () {
    const inputVal = $(this).val();
    const $commentInputBtn = $(".commentInput_box .commentInputBtn");
    if (inputVal.length > 0) {
      $commentInputBtn.addClass("is_active");
    } else {
      $commentInputBtn.removeClass("is_active");
    }
    // コメント欄に入力が1,000文字以上を入れると注意書きが出る
    const $commentInputAttentionText = $(this).siblings(
      ".commentInputAttentionText"
    );
    if (inputVal.length >= 1000) {
      $commentInputAttentionText.addClass("is_active");
    } else {
      $commentInputAttentionText.removeClass("is_active");
    }
  });

  //読み込み時に吹き出しの高さを計測。4行以上の高さだったら.is_accordionを付ける。「続きを読む」「閉じる」の対応の為。
  $(".comment_box .commentText").each(function () {
    const height = $(this).height();
    const $comment = $(this).parents(".comment");
    if (height >= 120) {
      // 高さ120pxを4行とする
      $comment.addClass("is_accordion");
    }
  });

  // 吹き出しの「続きを読む」「閉じる」の対応
  $(".comment_box .comment .more, .comment_box .comment .close").on(
    "click",
    function () {
      const $comment = $(this).closest(".is_accordion");
      const $commentText = $(this).siblings(".commentText");
      const commentTextheight = $commentText.get(0).scrollHeight;
      // is_activeを付け外し
      $comment.toggleClass("is_active");
      // 吹き出しが開くときのアニメーション用にmax-heightを付け外し
      if ($comment.hasClass("is_active")) {
        // is_activeが付いたときの吹き出しのpadding-bottomの値を取得
        const commentTextheightPaddingBottom = Number(
          $commentText.css("paddingBottom").replace(/px/, "")
        );
        $commentText.css(
          "maxHeight",
          commentTextheight + commentTextheightPaddingBottom
        );
      } else {
        $commentText.css("maxHeight", "");
      }
      // 「すべてのコメントを見る」ボタンでコメントたちが全数表示になっている時、heightをautoで上書き。吹き出しが広がりきらない為。
      $(this).closest(".overComment.is_active").height("auto");
      // リンク先に飛ばないように止める
      return false;
    }
  );

  // コメント数が多いときに「すべてのコメントを見る」ボタンを出して制御
  const $commentListLi = $(".commentList li");
  const commentListLiShowLength = 4; //最初に表示しておくコメント数
  if ($commentListLi.length > commentListLiShowLength) {
    $(".commentShowAllBtn_box").show();
  }
  // 多いコメントをclassを付けて隠す
  $commentListLi.each(function (index) {
    if (index < commentListLiShowLength) {
      return true;
    }
    $(this).addClass("overComment");
  });

  // 「すべてのコメントを見る」ボタンを押した対応
  $(".commentShowAllBtn_box button").on("click", function () {
    // 隠していたコメントを表示
    const $overComment = $(".commentList .overComment");
    $overComment.toggleClass("is_active");
    // コメントが開くときのアニメーション用にheightを付け外し
    $overComment.each(function () {
      if ($(this).hasClass("is_active")) {
        const overCommentheight = $(this).get(0).scrollHeight;
        $(this).height(overCommentheight);
      } else {
        $(this).height("");
      }
    });
    // 「すべてのコメントを見る」ボタンを入れ替え
    $(".commentShowAllBtn_box").toggleClass("is_active");
  });
});
// フォーム入力中のページ遷移アラート
$(function () {
  var changeFlg = false;
  // リロード・ブラウザバック時にアラート表示
  $(window).on("beforeunload", function () {
    if (changeFlg) {
      return "ページを移動しようとしています。\n入力した内容が失われますがよろしいですか？";
    }
  });

  // リンククリックした場合にアラート表示
  $("a").on("click", function (event) {
    // 別タブ/ウィンドウで開くクリックした時はアラート表示しない
    if (
      (event.button !== 0 && event.which !== 1) ||
      event.altKey ||
      event.ctrlKey ||
      event.shiftKey ||
      event.metaKey ||
      (typeof event.target.attributes.target !== "undefined" &&
        event.target.attributes.target.value == "_blank")
    ) {
      return;
    }
    if (changeFlg) {
      if (
        !confirm(
          "ページを移動しようとしています。\n入力した内容が失われますがよろしいですか？"
        )
      ) {
        return false;
      }
      // 遷移先でアラート表示しないようにfalseをセット
      changeFlg = false;
    }
  });

  //フォーム入力した時だけ表示(テキスト, 日付, 報酬額, 評価スコア)
  $(
    "form textarea, form input[type=date], form input[type=number], form input[type=radio]"
  ).on("change", function () {
    changeFlg = true;
  });

  //送信時は除外
  $("input[type=submit]").on("click", function () {
    changeFlg = false;
  });

  // 検索フォームに入力している時はアラート表示しない
  $("#jobSearchTextBox").on("change", function () {
    changeFlg = false;
  });
});

$(function () {
  /* いいね・感想送信モーダル表示時、入力欄にフォーカス */
  $("#sendReviewModal").on("shown.bs.modal", function () {
    $(".js_textarea", "#sendReviewModal").trigger("focus");
  });

  /* いいね・感想送信モーダル内の確認挙動 */
  $(".js_btn_confirm", "#sendReviewModal").on("click", function () {
    const reviewCommentContent = document.getElementById(
      "reviewCommentContent"
    ).value;
    document.getElementById("reviewCommentConfirm").textContent =
      reviewCommentContent;
    if (reviewCommentContent) {
      document.getElementById("reviewCommentConfirm").style.display = "block";
      document.getElementById("reviewConfirmWithoutComment").style.display =
        "none";
    } else {
      document.getElementById("reviewCommentConfirm").style.display = "none";
      document.getElementById("reviewConfirmWithoutComment").style.display =
        "block";
    }
    $("#sendReviewModal").addClass("is_confirm");
  });
  $(".js_btn_back", "#sendReviewModal").on("click", function () {
    $("#sendReviewModal").removeClass("is_confirm");
  });
});
